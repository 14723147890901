import React from "react"
import { Avatar, Badge, List, ListSubheader, Typography, Button } from "@material-ui/core"
import ReduxStore from '../components/app/store';
import { FRONTEND_URL, GREENWOODX_NETWORK_KEY, PURPLE, USER_TYPE } from "./constants"
import SchoolIcon from '@material-ui/icons/School';
import moment from "moment-timezone"

const Util = {
  getUserFullName:(user) => `${user.FirstName} ${user.LastName}`,
  getUserInitials: (user) => user.FirstName && user.LastName ? `${user.FirstName.charAt(0)}${user.LastName.charAt(0)}`: '',
  UserAvatar: (props) => (
    <Badge
      badgeContent={props.user.Type == USER_TYPE.STUDENT? <SchoolIcon style={{fontSize:'1rem'}}/>: 0}
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      color="primary">
      <Avatar
        {...props}
        alt={Util.getUserFullName(props.user)}
        src={props.user.ProfilePic}>
        {Util.getUserInitials(props.user)}
      </Avatar>
    </Badge>
  ),
  // TODO: Change usage of objects as hash map to use Map()
  arrayToMap: (array) => {
    const map = {};
    array.forEach((item) => map[item]=true);
    return map;
  },
  getTimezone: () => moment.tz.zone(moment.tz.guess()).abbr(new Date().getTime()),
  PoweredByGreenwoodx: ({networkKey}) => {
    if (networkKey == GREENWOODX_NETWORK_KEY) return null;
    return (
      <Button style={{textAlign: 'center', color: PURPLE}} href={FRONTEND_URL} target="_blank">
        Powered by Greenwoodx
      </Button>
    );
  },

  // TODO: Remove. Not sure if this is being used.
  getLocalAppSettings: () => JSON.parse(localStorage.get('localAppSettings')),
  setLocalAppSettings:(settings) => localStorage.setItem('localAppSettings', JSON.stringify(settings)),
  setLocalAppSetting: (key, value) => Util.setLocalAppSettings({...Util.getLocalAppSettings(), key: value}),
  getLocalAppSetting: (key) => Util.getLocalAppSettings()[key],
}
export default Util;
